







import BannersMixin, { BannersMixinTags } from '@/mixins/http/BannerMixin';
import Banner from '@/models/Banner';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
import { Mixins, Component } from 'vue-property-decorator';
import StoreFrontBannerUpsert from '@/components/StoreFront/Banner/Upsert.vue';

@Component({
  components: {
    StoreFrontBannerUpsert
  }
})
export default class StoreFrontContentBannerUpdate extends Mixins(BannersMixin) {
  private banner: Banner | null = null;

  created() {
    this.loadBanner();
  }

  get bannerId() {
    return this.$route.params.bannerId;
  }

  get showTag() {
    return `${BannersMixinTags.Show}-${this.bannerId}`;
  }

  private async loadBanner() {
    const [data, errors] = await easync(this.getBanner(this.bannerId, this.showTag));

    if (data) {
      this.banner = data;
    } else {
      this.banner = null;
      showErrorToast('Si è verificato un errore');
    }
  }
}
